.hero.has-background {
    position: relative;
    overflow: hidden;
  }
  .hero-background {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
  }
  .hero-background.is-transparent {
    opacity: 0.3;
  }
  .sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
